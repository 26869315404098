<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="$t('overview.my_x', [this.formatModuleName(this.$route.name, this.$i18n)])"
        :sort="'id'"
        :sortOrder="-1"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields="selectFields"
        :deleteText="setDeleteText"
    >
        <template #columns>
            <Column field="number" :header="$t('overview.number')"></Column>
            <Column field="office.relation.name" :header="$t('crm.company')"></Column>
            <Column field="type" :header="$t('overview.type')"></Column>
            <Column field="voyage.number" :header="$t('navigation.voyages')">
                <template #body="slotProps">
                    {{slotProps.data.type == "voyage" && slotProps.data.voyage ? (slotProps.data.voyage.name) : slotProps.data.voyage_manual}}
                </template>
            </Column>
        </template>

        <template #form>
            <MainportForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import MainportForm from '@/components/legacy/MainportForm.vue';
import MainportProjectService from '@/services/MainportProjectService';

export default {
    components: {
        Overview,
        Column,
        MainportForm
    },
    data() {
        return {
            item: {},
            itemService: MainportProjectService,
            itemFormRef: null,
            selectFields: [
                "id",
                "number",
                "type",
                "office_id",
                "voyage_id",
                "voyage_manual",
                {
                    "voyage": [
                        "voyage.id",
                        "voyage.number",
                        "voyage.name"
                    ]
                },
                {
                    "office":[
                        "office.id",
                        "office.relation_id",
                        {
                            "relation": [
                                "relation.id",
                                "relation.name"
                            ]
                        }
                    ]
                }
            ],
            setDeleteText: function(item){
                return item.number;
            }
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>